import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import aboutus from "../assets/img/AboutUS/aboutus.jpg";
import Video1 from "../assets/videos/video4.mp4";

export default function AboutUs() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };

  return (
    <Wrapper>
      <HeroSection>
        <HeroContent>
          <Title>About Planup Mining Group</Title>
          <Subtitle>
            Welcome to Planup Mining Group, a leader in the mining industry known for our innovative and sustainable approach to resource extraction.
          </Subtitle>
          {/* <CTAButton>Start a Free 10-Day Trial</CTAButton> */}
        </HeroContent>
        <HeroImage src={aboutus} alt="Construction Site" />
      </HeroSection>
      <Content>
        <Section>
          <Paragraph>
            Founded in 2005, our company has rapidly grown to become a global player, committed to excellence in every aspect of our operations.
          </Paragraph>
        </Section>
        <Section>
          <SectionTitle>Our Mission</SectionTitle>
          <Paragraph>
            Our mission is to deliver high-quality mining solutions through innovation, efficiency, and a commitment to sustainability.
          </Paragraph>
        </Section>
        <Section>
          <SectionTitle>Our Vision</SectionTitle>
          <Paragraph>
            Our vision is to be the leading global mining company, known for our sustainable practices, technological advancements, and exceptional workforce.
          </Paragraph>
        </Section>
        <Section>
          <SectionTitle>Our Commitment</SectionTitle>
          <Paragraph>
            We are dedicated to fostering a safe and proficient workforce through comprehensive training and development programs.
          </Paragraph>
        </Section>
        <Section>
          <Paragraph>
            Led by our visionary CEO, Duke Lamptey, Planup Mining Group continuously pushes the boundaries of what is possible in the mining industry.
          </Paragraph>
        </Section>
        <Section>
          <Paragraph>
            Join us as we pave the way for a sustainable and prosperous future in mining. Together, we can achieve remarkable outcomes and make a lasting impact on the world.
          </Paragraph>
        </Section>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  background: #f4f4f9;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  background: linear-gradient(90deg, #1a2a6c, #b21f1f, #fdbb2d);
  color: #fff;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 50px;
  }
`;

const HeroContent = styled.div`
  max-width: 600px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CTAButton = styled.button`
  padding: 15px 30px;
  background: #ff4081;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
    width: 50%;
  }
`;

const Content = styled.div`
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px 0;

  img {
    width: calc(50% - 20px);
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    img {
      width: 100%;
    }
  }
`;

const SliderWrapper = styled.div`
  margin: 50px 0;

  .slick-slider {
    position: relative;
  }

  .slick-dots {
    bottom: -30px;

    li button:before {
      font-size: 12px;
      color: #ff4081;
    }

    li.slick-active button:before {
      color: #ff4081;
    }
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;

    &:before {
      font-size: 40px;
      color: #ff4081;
    }
  }

  img, video {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const Slide = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
