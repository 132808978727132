import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { SnackbarProvider } from 'notistack';

// Screens
import Landing from "./screens/Landing.jsx";
import AboutUs from "./screens/AboutUs.jsx";
import PlanupLandingPage from "./screens/PlanupLandingPage.jsx";
import Services from "./screens/Services.jsx";
import ServiceDetail from "./screens/ServiceDetail.jsx";
import RegistrationForm from "./screens/ForkliftRegistration.jsx";

export default function App() {
  return (
    
    <Router>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:serviceId" element={<ServiceDetail />} /> 
        <Route path="/PlanupLandingPage" element={<PlanupLandingPage />} />
        <Route path="RegistrationForm" element={<RegistrationForm />} />
      </Routes>
    </Router>
  );
}
