import React, { useState } from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';

const testimonials = [
  {
    quote: 'Planup Mining Group is a game changer in the mining industry!',
    name: 'John Smith',
    position: 'Industry Analyst',
  },
  {
    quote: 'Their commitment to sustainability is truly inspiring.',
    name: 'Alice Johnson',
    position: 'Environmental Scientist',
  },
  // Add more testimonials as needed
];

const timelineEvents = [
  {
    year: '2005',
    event: 'Company founded with a mission to innovate in the mining industry.',
  },
  {
    year: '2010',
    event: 'Expanded operations to 10 countries.',
  },
  {
    year: '2015',
    event: 'Awarded for excellence in sustainable practices.',
  },
  // Add more events as needed
];

const Statistics = () => {
  const [startCount, setStartCount] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setStartCount(true);
      }
    },
  });

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };

  return (
    <StatisticsSection ref={ref}>
      <SectionTitle>Statistics</SectionTitle>
      <Stats>
        <Stat>
          <StatNumber>{startCount && <CountUp end={400} duration={6} />}+</StatNumber>
          <StatLabel>Students</StatLabel>
          <ProgressBar>
            <Progress value={startCount ? 500 : 0} max={500} />
          </ProgressBar>
        </Stat>
        <Stat>
          <StatNumber>{startCount && <CountUp end={5} duration={6} />}+</StatNumber>
          <StatLabel>High Risk Projects</StatLabel>
          <ProgressBar>
            <Progress value={startCount ? 2000 : 0} max={2000} />
          </ProgressBar>
        </Stat>
        <Stat>
          <StatNumber>{startCount && <CountUp end={15} duration={6} />}+</StatNumber>
          <StatLabel>Mineral Exploration</StatLabel>
          <ProgressBar>
            <Progress value={startCount ? 50 : 0} max={50} />
          </ProgressBar>
        </Stat>
      </Stats>
      {/* <TimelineSection>
        <SectionTitle>Timeline</SectionTitle>
        <Timeline>
          {timelineEvents.map((event, index) => (
            <TimelineEvent key={index}>
              <EventYear>{event.year}</EventYear>
              <EventDescription>{event.event}</EventDescription>
            </TimelineEvent>
          ))}
        </Timeline>
      </TimelineSection>
      <TestimonialsSection>
        <SectionTitle>Testimonials</SectionTitle>
        <TestimonialSlider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <Quote>{testimonial.quote}</Quote>
              <Author>
                {testimonial.name}, {testimonial.position}
              </Author>
            </Testimonial>
          ))}
        </TestimonialSlider>
      </TestimonialsSection> */}
    </StatisticsSection>
  );
};

const StatisticsSection = styled.div`
  margin-bottom: -50px;
  background-color: white;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  background: linear-gradient(to bottom, #f9f9f9 0%, #fff 100%);
  animation: fadeInBackground 2s ease-in-out;
  @keyframes fadeInBackground {
    from {
      background-position: top;
    }
    to {
      background-position: bottom;
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2b5876;
  text-align: center;
  animation: fadeIn 2s ease-in;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 40px 0;
  flex-wrap: wrap;
`;

const Stat = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 10px 0;
  animation: zoomIn 2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const StatNumber = styled.h3`
  font-size: 3.5rem;
  font-weight: 700;
  color: #2b5876;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const StatLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: #777;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ProgressBar = styled.div`
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 8px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
    animation: shimmer 2s infinite;
  }
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Progress = styled.div`
  background: #4e4376;
  height: 100%;
  width: ${(props) => (props.value / props.max) * 100}%;
  transition: width 1s ease-in-out;
`;

const TimelineSection = styled.div`
  margin-top: 50px;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TimelineEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in-out;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const EventYear = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #2b5876;
  margin-right: 20px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const EventDescription = styled.p`
  font-size: 1.2rem;
  color: #555;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const TestimonialsSection = styled.div`
  margin-top: 50px;
`;

const TestimonialSlider = styled(Slider)`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-dots {
    bottom: -30px;
  }
`;

const Testimonial = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  text-align: center;
  animation: fadeInUp 1s ease-in-out;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
  }
`;

const Quote = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  color: #555;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Author = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #2b5876;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export default Statistics;
