import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "../../style/carousel.css"; 

// Paths to the images
import Ppe1 from "../../assets/img/projects/1.png";
import Ppe2 from "../../assets/img/projects/2.png";
import Ppe3 from "../../assets/img/projects/3.png";
import Ppe4 from "../../assets/img/projects/4.png";
import Ppe5 from "../../assets/img/projects/5.png";
import Ppe6 from "../../assets/img/projects/6.png";
import Ppe7 from "../../assets/img/projects/7.png";
import Ppe8 from "../../assets/img/projects/8.png";
import Ppe9 from "../../assets/img/projects/9.png";

const projectsData = [
  { title: 'Ppes', description: '@Planup', image: Ppe1 },
  { title: 'HV Uniform', description: '@Planup', image: Ppe2 },
  { title: 'Safety Boot', description: '@Planup', image: Ppe3 },
  { title: 'Helmets', description: '@Planup', image: Ppe4 },
  { title: 'Harness', description: '@Planup', image: Ppe5 },
  { title: 'Googles', description: '@Planup', image: Ppe6 },
  { title: 'Harness', description: '@Planup', image: Ppe7 },
  { title: 'Hearing Protection', description: '@Planup', image: Ppe8 },
  { title: 'Safety Boots', description: '@Planup', image: Ppe9 },
];

const Projects = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const NextArrow = ({ onClick }) => {
    return (
      <Arrow className="next" onClick={onClick}>
        <FaArrowRight />
      </Arrow>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <Arrow className="prev" onClick={onClick}>
        <FaArrowLeft />
      </Arrow>
    );
  };

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <ProjectsSection id="projects">
      <Container>
        <Title>Our Protective Equipments - PPE</Title>
        <StyledSlider {...settings}>
          {projectsData.map((project, idx) => (
            <ProjectItem key={idx} className={idx === imageIndex ? "slide activeSlide" : "slide"}>
              <Image src={project.image} alt={project.title} />
              <ProjectInfo>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
              </ProjectInfo>
            </ProjectItem>
          ))}
        </StyledSlider>
      </Container>
    </ProjectsSection>
  );
};

const ProjectsSection = styled.div`
  background-color: #e0f7fa;
  padding: 50px 0;
`;

const Container = styled.div`
  max-width: 1100px;
  height:520px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;
  font-weight: bold;
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li.slick-active button:before {
    color: #002366;
  }
`;

const ProjectItem = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.activeSlide {
    transform: scale(1.1);
    transition: transform 0.3s, opacity 0.5s, box-shadow 0.5s;
    box-shadow: 0 0 20px 10px rgba(0, 123, 255, 0.5);
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ProjectInfo = styled.div`
  padding: 0 20px;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #002366;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.next {
    right: -40px;
  }

  &.prev {
    left: -40px;
  }

  @media (max-width: 600px) {
    &.next {
      right: -20px;
    }

    &.prev {
      left: -20px;
    }
  }
`;

export default Projects;
